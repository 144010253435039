.responsive-image {
    width: 40%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    img.responsive-image {
      width: 100% !important;
      height: auto !important;
    }
  }
  