

.dir-ltr {
  direction: ltr;
}


.dir-rtl {
  direction: rtl;
}
/* Add these styles to your CSS file */
.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}
/* For LTR (Left-to-Right) text */
.text-ltr {
  direction: ltr;
  text-align: left;
}

/* For RTL (Right-to-Left) text */
.text-rtl {
  direction: rtl;
  text-align: right;
}




@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: Arial;
  font-size: 1rem;
  direction:ltr;
}

body {
  margin: 0;
  font-family:  sans-serif;
  direction:rtl;
}

.AboutAs {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
}

.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) fixed center center;
}




.text-custom-color {
  color: #61C435;
 
}

.text-custom-color2 {
  color: #203749;
 
}




.bg-custom-bg-color {
  background-color: #203749;
  
}
.bg-custom-bg-color2 {
  background-color: #61C435;
  
}



/* aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa */

.nav-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navigation-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav-link {
  padding: 0.5rem;
  font-weight: bold;
  color: gray;
}

.nav-link:hover {
  color: green;
}

.flags-container {
  margin-left: 1rem;
  display: flex;
  align-items: center;
}

.flag {
  width: 24px;
  height: 24px;
  margin: 0 5px;
  cursor: pointer;
  margin-right: 10px; 

}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav-container {
    flex-direction: column;
  }

  .navigation-container {
    flex-direction: column;
  }

  .flags-container {
    margin-top: 1rem;
    margin-left: 0;
    display: flex;
    flex-direction: row; /* Ensure flags are in a row */
  }

  .flag {
    margin: 0 10px; /* Add horizontal margin between flags */
  }


  
}
@media screen and (max-width: 768px) {
  #blog-section .ltr-content {
    direction: ltr !important;
    text-align: left !important;
  }
}
.navigation-container {
  direction: rtl !important;
  text-align: right !important;
}
