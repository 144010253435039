/* Ensure NavBar and NavLinks always stay LTR */
.ltr-container {
  direction: ltr !important;
  text-align: left !important;
}

/* Default styles for navigation links */
.nav-link {
  font-size: 20px;
}

/* Navigation container */
.navigation-container {
  direction: ltr !important;
  text-align: left !important;
  margin-right: 20px;
}

/* Flag container */
.flags-container {
  direction: ltr;
}

/* Logo size adjustments */
.logo {
  width: 50%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .logo {
      width: 5rem;
      height: 4rem;
  }

  nav {
      height: 80px;
  }

  button {
      height: 70px;
  }

  .fixed.transition-transform {
      height: calc(100vh - 80px);
  }
}
