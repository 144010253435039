/* Default styling remains for desktop */

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  main {
    margin: 0 auto; /* Reset margins */
    padding: 0 15px; /* Optional: Adjust padding for smaller screens */
  }

   p, ul, li {
    margin: 0!important; /* Remove all margins */
  }

  ul {
    padding: 0!important; /* Reset padding for lists */
  }


  h1 {
    font-size: 2rem!important; /* Adjust to a smaller size for mobile */
    line-height: 1.4!important; /* Improve spacing */
  }

  h2 {
    font-size: 1.8rem!important; /* Adjust to a smaller size for mobile */
    line-height: 1.4!important; /* Improve spacing */
  }
}
